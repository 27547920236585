import {
  Cloud$PurchaseModelCodeBuyNow,
  Cloud$PurchaseModelCodeEnum,
  Cloud$PurchaseModelCodeLeasing,
  Cloud$PurchaseModelCodeLoan,
} from './types/cloudEnums';
import {
  Cloud$CommissionStatusEnum,
  Cloud$ContractPrerequisitesEnum,
  Cloud$DiscountTypeEnum,
  Cloud$InterestStatusEnum,
  Cloud$LoanForwardStrategyEnum,
  Cloud$LoanStatusEnum,
  Cloud$ProductNameEnum,
  Cloud$ProjectStationEnum,
  Cloud$ReferralSourceEnum,
  Cloud$SigningOptionEnum,
  Cloud$SubsidyTypeEnum,
} from './types/cloudEnums';

export const projectStation: Cloud$ProjectStationEnum = Object.freeze({
  QUALIFICATION: 'qualification',
  FEASIBILITY: 'feasibility',
  ASSIGNING: 'assigning',
  ENGINEERING: 'engineering',
  PERMITTING: 'permitting',
  SCHEDULING: 'scheduling',
  PENDING_INSTALLATION: 'pending_installation',
  INSTALLATION: 'installation',
  DOCUMENTATION: 'documentation',
  CONTROL_AND_FINALISATION: 'control_and_finalisation',
  COMPLETED: 'completed',
});

export const loanStatus: Cloud$LoanStatusEnum = Object.freeze({
  INITIALIZED: 'initialized',
  CREATED: 'created',
  CREDIT_CHECK: 'credit_check',
  CREDIT_CHECK_FAILED: 'credit_check_failed',
  CREDIT_CHECK_OK: 'credit_check_ok',
  LOAN_SIGNED: 'loan_signed',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  REDACTED: 'redacted',
});

export const loanForwardStrategy: Cloud$LoanForwardStrategyEnum = Object.freeze(
  {
    HTTP_PING: 'HTTP_PING',
    HTTP_REDIRECT: 'HTTP_REDIRECT',
    UNDEFINED: 'UNDEFINED',
  },
);

export const interestStatus: Cloud$InterestStatusEnum = Object.freeze({
  SPECIAL_CASE: 'special_case',
  NO_SURFACES_FOUND: 'no_surfaces_found',
  CALC_FAILED: 'calc_failed',
  GHOST: 'ghost',
  AWAITING_LAUNCH: 'awaiting_launch',
  REPLACED: 'replaced',
  AWAITING_CALC: 'awaiting_calc',
  CALC_CREATED: 'calc_created',
  CALC_ACCEPTED: 'calc_accepted',
  IN_DIALOG: 'in_dialog',
  OFFER_CREATED: 'offer_created',
  OFFER_ACCEPTED: 'offer_accepted',
  LOST: 'lost',
  OUTSIDE_SERVICE_AREA: 'outside_service_area',
  WON: 'won',
});

export const productName: Cloud$ProductNameEnum = Object.freeze({
  STANDARD: 'standard',
  PREMIUM: 'premium',
  TIER3: 'tier3',
  UNKNOWN: 'unknown',
  CUSTOM: 'custom',
});

export const purchaseModelCodeBuyNow: Cloud$PurchaseModelCodeBuyNow = 'NOW';
export const purchaseModelCodeLoan: Cloud$PurchaseModelCodeLoan = 'LOAN';
export const purchaseModelCodeLeasing: Cloud$PurchaseModelCodeLeasing =
  'LEASING';

export const purchaseModelCode: Cloud$PurchaseModelCodeEnum = Object.freeze({
  BUY_NOW: purchaseModelCodeBuyNow,
  LOAN: purchaseModelCodeLoan,
  LEASING: purchaseModelCodeLeasing,
});

export const discountType: Cloud$DiscountTypeEnum = Object.freeze({
  FREE_MONTHS: 'free_months',
  PERCENTAGE: 'percentage',
  FIXED: 'fixed',
  FIXED_PRICE: 'fixed_price',
});

export const subsidyType: Cloud$SubsidyTypeEnum = Object.freeze({
  CH_FEDERAL_SUBSIDY: 'federal-subsidy',
  CH_TAX_REBATE_SUBSIDY: 'tax-rebate',
  FR_SELF_CONSUMPTION_SUBSIDY: 'french-self-consumption-subsidy',
  IT_ECOBONUS: 'it-ecobonus',
  NO_ENOVA_2020: 'enova-2020',
  NO_ENOVA: 'enova',
  NO_OSLO_2016: 'oslo-2016',
  NO_OSLO_2017: 'oslo-2017',
  NO_VFK: 'vfk',
  NONE: 'none',
  PL_2020: 'pl-2020',
  SE_GRONT_AVDRAG_2020: 'se-gront-avdrag-2020',
  SE_ROT: 'se_rot',
  SE_SIS_2019: 'sis-2019',
  UNKNOWN: 'unknown',
});

export const signingOptions: Cloud$SigningOptionEnum = Object.freeze({
  SCRIVE: 'scrive',
  SMS: 'sms',
});

export const contractPrerequisiteEnum: Cloud$ContractPrerequisitesEnum =
  Object.freeze({
    CADASTRAL_ID: 'cadastral_id',
    IBAN: 'iban',
    SSN: 'ssn',
    BIRTH_PLACE: 'birth_place',
    BIRTH_DATE: 'birth_date',
    DOCUMENT_ID: 'document_id',
    CREDIT_CHECK: 'credit_check',
    DUTY_OF_CARE: 'duty_of_care',
    CREDIT_AND_AFFORDABILITY_CHECK: 'credit_and_affordability_check',
  });

export const commissionStatusEnum: Cloud$CommissionStatusEnum = Object.freeze({
  PENDING: 'pending',
  CREATED: 'created',
  PAID: 'paid',
  LOST: 'lost',
});

export const referralSourceEnum: Cloud$ReferralSourceEnum = Object.freeze({
  POST_ACTIVATION_FORM: 'post-activation-form',
  ONBOARDING_WIZARD: 'onboarding-wizard',
  MYPAGE: 'mypage',
  ADVOCATE_LANDING_PAGE: 'advocate-landing-page',
  FSM_PAMPHLET: 'fsm-pamphlet',
  OFFER_PAGE: 'offer-page',
});
